html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  font-weight: 400;
  letter-spacing: .018em;
  background: rgba(242, 244, 247, 1);
}

.page-container {
  display: flex;
  justify-content: center;
  margin: 100px auto;
  padding: 72px 48px;
  max-width: 1176px;
  background: #F5F7FA;
  border-radius: 12px;
  box-shadow: rgb(255, 255, 255) -4px -4px 4px 0px, rgba(121, 144, 161, 0.35) 3px 3px 6px 0px;


  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 5%;
    max-width: 100%;
    min-height: 100%;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-around;
  max-width: 420px;
  padding-right: 20px;
}

.image-wrapper {
  width: 600px;
  min-height: 480px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1024px) {
    max-width: 100%;
    min-height: fit-content;
    padding-right: 20px;
  }
}

img {
  width: 100%;
}

svg {
  width: 100%;
}

.title {
  line-height: 50px;
  font-size: 40px;
  font-weight: 900;
  color: rgba(32, 39, 48, 1);
}

.subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  color: rgba(32, 39, 48, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}

p {
  margin: 0;
}

.list {
  padding-left: 12px;
  list-style: none;
  line-height: 25.6px;
}

.list-element::before {
  content: '•';
  padding-right: 7px;
  color: rgba(32, 39, 48, 1);
}

a {
  color: rgba(0, 162, 255, 1);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

.go-back-link {
  display: block;
  margin-top: 5px;
}
